import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import Footer from "components/footer/FooterAdmin";
import Navbar from "components/navbar/NavbarRegister";
import Sidebar from "components/sidebar/SidebarRegister";
import { SidebarContext } from "contexts/SidebarContext";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";

import { useUserStore } from "store/reducers/user";
import { log } from "util";

export default function Dashboard(props: any) {
  const auth = useUserStore((state) => state.auth);

  const [aprovado, setAprovado] = useState(false);

  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes: any) => {
    let activeRoute = "Seja bem vindo!";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes: any) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: any) => {
    if (!aprovado) {
      return routes.map((route: any, key: any) => {
        if (route.layout === "/register") {
          return (
            <Route
              path={route.layout + route.path}
              component={route.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return routes.map((route: any, key: any) => {
        if (route.layout === "/admin") {
          return (
            <Route
              path={route.layout + route.path}
              component={route.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    }
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  // useEffect(() => {
  //  console.log('pedindo rotas');
  //  if(!auth.user.complete_register){
  //   window.location.href = '#/admin/perfil';
  //  }else{
  //   window.location.href = '#/admin/dashboard';
  //  }
  // }, [aprovado]);

  const fetchDataStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cadastro`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const parsedData = response.data[0];
      if (parsedData.analise !== "Aprovado") {
        setAprovado(false);
      } else {
        setAprovado(true);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchDataStatus();
    const intervalId = setInterval(fetchDataStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100%  )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText="Horizon UI Dashboard PRO"
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/admin" />
              </Switch>
            </Box>
          ) : (
            props.children
          )}

          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
