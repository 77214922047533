import React from "react";
import { Box, Button, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import CheckTable from "views/admin/indicados/CheckTableSellerIndication";
import { useUserStore } from "store/reducers/user";
import LoadingPage from "components/Loading/LoadingPage";
import {
  SellerIndicationResponse,
  SellerIndication as SellerIndicationModel,
} from "models/SellerIndication";
import SellerIndicationForm from "./SellerIndicationForm";

import Card from "components/card/Card";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import BalanceCard from "./components/BalanceCard";
import { usePaymentStore } from "store/reducers/payments/paymentArea";
import { usePaymentRequestStore } from "store/reducers/payments/paymentRequests";
import { useEffect, useState } from "react";

import IconBox from "components/icons/IconBox";
import { MdApproval, MdCancel, MdRequestPage } from "react-icons/md";
import DetailsText from "components/details/DetailsText";
import axios from "axios";
import { formatCurrency } from "./currency";
import {
  formatCentToReal,
  formatCentToRealString,
} from "../pagamento/formatCentToReal";
import { Text as CustomText } from "styleguide/Typograph";
import { BodyText } from "./components/styles";
import { notifyError, notifySuccess } from "utils/notification";
export default function SellerIndication() {
  const { hasPendingPaymentRequests, requestAnticipation, requestWithdraw } =
    usePaymentRequestStore();

  const { getBalance, balance, getDebtsAndCredit, debtsAndCredits } =
    usePaymentStore();
  const [buttonBalance, setButtonBalance] = useState(true);

  const auth = useUserStore((state) => state.auth);
  const token = useUserStore((state) => state.auth.token);

  const [page, setPage] = React.useState<number>(1);
  const FIRST_PAGE = 1;

  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<SellerIndicationModel>(null);
  const [show, setShow] = React.useState(false);
  const [sellerIndicationData, setSellerIndicationData] =
    React.useState<SellerIndicationResponse>([]);
  const [confirmDeletion, setConfirmDeletion] = React.useState<number>(null);
  const [details, setDetails] = React.useState<SellerIndicationModel>(null);

  const switchShow = () => {
    setDetails(null);
    setSelected(null);
    setShow(!show);
  };

  const select = (sellerIndication: SellerIndicationModel) => {
    setSelected(sellerIndication);
    setShow(true);
  };

  const [email, setEmail] = React.useState("");
  const [totalIndications, settotalIndications] = React.useState("");
  const [totalGanhos, settotalGanhos] = React.useState("");
  const [totalPayment, settotalPayment] = React.useState("");
  const [totalRetirar, settotalRetirar] = React.useState("");

  const handleGetTotalOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller-indication/totalOrders?user_indication_from_id=` +
          auth.user.id,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const parsedData = response.data;
      settotalGanhos(parsedData);
      return parseFloat(parsedData);
    } catch (error) {
      // Handle error
    } finally {
      // Set isLoading to false to hide the preloader

      setLoading(false);
    }
  };

  const handleGetTotalPayment = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller-indication/totalPayment?user_id=` +
          auth.user.id,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const parsedData = response.data;
      settotalPayment(parsedData);
      return parseFloat(parsedData);
    } catch (error) {
      // Handle error
    } finally {
      // Set isLoading to false to hide the preloader

      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const totalOrdersF = await handleGetTotalOrders();
      const totalPaymentF = await handleGetTotalPayment();
      await checkButton(totalOrdersF - totalPaymentF);
    })();
  }, []);

  const handleGetEmail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cadastro`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const parsedData = response.data[0];
      setEmail(parsedData.email);
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const handleGetSellerIndications = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller-indication/list?page=` +
          FIRST_PAGE,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const parsedData = response.data;
      setSellerIndicationData(parsedData.data);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const handleGetTotalIndications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller-indication/totalActive?user_indication_from_id=` +
          auth.user.id,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const parsedData = response.data;
      settotalIndications(parsedData);
    } catch (error) {
      // Handle error
    } finally {
      // Set isLoading to false to hide the preloader

      setLoading(false);
    }
  };

  const requestWithdrawIndication = async (userId: number, amount: number) => {
    const formData = new FormData();
    formData.append("userId", auth.user.id + "");
    formData.append("amount", amount.toString());
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seller-indication/withdraw`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const parsedData = response.data;
      if (parsedData.success) {
        notifySuccess("Solicitação feita com sucesso!");
        handleGetTotalOrders();
        handleGetTotalPayment();
      } else {
        notifyError("Erro ao fazer solicitação: " + parsedData.message);
      }
      //settotalIndications(parsedData);
    } catch (error) {
      // Handle error
    } finally {
      // Set isLoading to false to hide the preloader

      setLoading(false);
    }
  };

  const handleSetSellerIndication = async () => {
    const formData = new FormData();
    formData.append("user_indication_from_id", auth.user.id + "");
    formData.append("email", "");
    formData.append("status", "Inativo");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/seller-indication`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
    } catch (error) {}
  };

  React.useEffect(() => {
    handleGetSellerIndications();
    handleGetEmail();
    handleGetTotalIndications();
    //handleSetSellerIndication();
  }, []);

  const handleGetAmount = async () => {
    setLoading(true);
    try {
      const response = await getBalance({
        contractId: auth.user.contractId.toString(),
      });
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleGetAmount();
  }, []);

  const checkButton = (availableAmount: number) => {
    setLoading(true);
    if (availableAmount > 0) {
      setButtonBalance(false);
    }
    setLoading(false);
  };

  const requestWithdrawCb = async (userId: number, amount: number) => {
    (async () => {
      try {
        console.log({
          amount: amount,
        });
        const response = await requestWithdraw({
          amount: amount,
          userId: userId,
        });
        if (response.success) {
          notifySuccess("Solicitação feita com sucesso!");
          setButtonBalance(true);
        } else {
          notifyError("Erro ao fazer solicitação!");
        }
      } catch (err) {
        notifyError("Erro ao fazer solicitação!");
      }
    })();
  };

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const renderCard = () => {
    if (!show) {
      return (
        <Card
          flexDirection="column"
          marginTop={"10px"}
          w="100%"
          px="20px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
          pt="80px"
        >
          {/* <Button onClick={switchShow} w="120px">
          {show ? "Voltar" : "Nova Indicação"}
        </Button> 
        */}
          <CustomText type={"h3"}>Indique e ganhe</CustomText>
          {/* <Box py='20px'>
          <DetailsText title={"Compartilhe o link abaixo com seu amigo"} value={process.env.REACT_APP_FRONT_URL+'/sign-up?email=' + email + '#/auth/sign-up'} />
        </Box> */}

          <Box>
            <SimpleGrid
              columns={{ base: 1, lg: 3, "2xl": 3 }}
              // columns={4}
              gap="20px"
            >
              <GridItem>
                <BalanceCard
                  name="Disponível para saque"
                  value={
                    formatCurrency(
                      parseFloat(totalGanhos) - parseFloat(totalPayment),
                      -2,
                      "USD"
                    ) || "0.00"
                  }
                  //value={formatCurrency(parseFloat(totalGanhos) - parseFloat(totalPayment), -2, 'USD')}
                  description={"Valor disponível para saque"}
                  buttonText="Sacar"
                  disableButton={false}
                  onClickButton={(userId: number, amount: number) => {
                    requestWithdrawIndication(userId, amount);
                  }}
                />
              </GridItem>
              <GridItem>
                <MiniStatistics
                  name="Indicações Ativas"
                  value={totalIndications}
                />
              </GridItem>

              <GridItem>
                <MiniStatistics
                  name="Ganhos efetuados"
                  value={formatCurrency(parseFloat(totalPayment))}
                />
              </GridItem>
            </SimpleGrid>
          </Box>

          <Box pt={{ base: "130px", xl: "40px" }}>
            <SimpleGrid columns={{ base: 1, xl: 1 }} gap="20px" mb="20px">
              <CheckTable
                tableData={sellerIndicationData}
                tableName="Minha indicações"
                select={select}
                requestDelete={null}
                page={page}
                setPage={setPage}
                //showDetails={showDetails}
              />
            </SimpleGrid>
          </Box>
        </Card>
      );
    }
    return (
      <SellerIndicationForm
        switchShow={switchShow}
        show={show}
        handleGetSellerIndications={handleGetSellerIndications}
      />
    );
  };

  return (
    <>
      <LoadingPage isOpen={loading} />
      <SimpleGrid columns={{ base: 1, sm: 1, xl: 2 }} gap="30px">
        <GridItem>{renderCard()}</GridItem>
        <GridItem>
          <BodyText>
            <CustomText type="h3">Programa indique e ganhe</CustomText>
            <CustomText type="subtitle">
              Ganhe dinheiro indicando amigos! Inscreva-se, compartilhe seu link
              exclusivo e ganhe 1,5% de comissão em todas as vendas dos seus
              indicados durante 6 meses. Quanto mais amigos você indicar, mais
              você ganha. Simples e lucrativo! A seguir como participar:
            </CustomText>
            <br />
            <br />
            <CustomText type="h5">
              <b>1 - Copie o link abaixo</b>
            </CustomText>
            <span
              style={{
                padding: "3px",
                backgroundColor: "yellow",
                display: "inline",
                color: "black",
              }}
            >
              {process.env.REACT_APP_FRONT_URL +
                "/sign-up?email=" +
                email +
                "#/auth/sign-up"}
            </span>
            <br />
            <br />
            <CustomText type="h5">
              <b>2 - Compartilhe ele com seus amigos</b>
            </CustomText>
            <CustomText type="subtitle">
              Compartilhe-o através de mensagens, redes sociais, e-mails ou da
              maneira que você preferir.{" "}
            </CustomText>
            Quando alguém clica no seu link, eles serão direcionados para a
            nossa plataforma.
            <br />
            <br />
            <CustomText type="h5">
              <b>3 - Seu amigo se inscreve e compra</b>
            </CustomText>
            <CustomText type="subtitle">
              Não há limites para o quanto você pode ganhar - quanto mais seus
              amigos comprarem, mais você ganhará!
            </CustomText>
          </BodyText>
        </GridItem>
      </SimpleGrid>
    </>
  );
}
