import axios from "axios";
import { useUserStore } from "store/reducers/user";

const clearProductsData = useUserStore.getState().clearProductsData;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = useUserStore.getState().auth.token;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
