import React, { useState, useEffect } from "react";
import {
  Box,
  Tag,
  FormControl,
  FormLabel,
  Button,
  Checkbox,
  Flex,
  SlideFade,
  Icon,
} from "@chakra-ui/react";
import DubpayLogo from "../dubpay_logo.png";
import axios from "axios";
import {
  Container,
  SelectTypeUserBody,
  SelectTypeUserContainer,
  BodyIcon,
  SelectTypeModal,
  Title,
  Row,
} from "./styles";
import { FcFactory, FcBusinessman } from "react-icons/fc";
import { H1, H4, H5 } from "components/typograph";
import { useUserStore } from "store/reducers/user";
import { updateOnboardingStep } from "services/userRequests";
import { usePerfilPage } from "../../store";
import { Text as CustomText } from "styleguide/Typograph";
import CustomButton from "styleguide/Button";
import { toastSuccess, toastError } from "styleguide/Toastify";

const FinishStep = () => {
  const auth = useUserStore((state) => state.auth);
  const { onboardingPage, setOnBoardingPage, setHasCnpj } = usePerfilPage();

  const [user] = useUserStore((state) => [state.user]);
  const [activeTab, setActiveTab] = React.useState(5);
  const [planos, setPlanos] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(false);

  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
  }, []);

  const handleNextPage = async (prefersCnpj: "cnpj" | "cpf") => {
    try {
      setHasCnpj(prefersCnpj === "cnpj");
      if (onboardingPage == 0) {
        const response = await updateOnboardingStep({
          onboarding_step: 1,
          type: prefersCnpj,
        });

        if (response.success) {
          setFade(false);
          toastSuccess("Operação realizada com sucesso");
          setTimeout(() => {
            setOnBoardingPage(0 + (prefersCnpj === "cnpj" ? 1 : 0.5));
          }, 350);
        }
      } else {
        await updateOnboardingStep({
          onboarding_step: user.onboarding_step,
          type: prefersCnpj,
        });

        setFade(false);
        toastSuccess("Operação atualizada com sucesso");
        setTimeout(() => {
          setOnBoardingPage(1);
        }, 350);
      }
    } catch (err) {
      toastError(
        "Houve uma falha na operação. Tente novamente ou entre em contato com o suporte"
      );
    }
  };
  // const handleCheckboxChange = async (planosId: string) => {
  //   setIsChecked(!isChecked);
  //   const formData = new FormData();
  //   formData.append("userId", auth.user.id + "");
  //   formData.append("membershipPlanId", planosId);
  //   // Disparar ação para a API
  //   if (!isChecked) {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/seller_plan`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${auth.token}`
  //           }
  //         }
  //       );
  //     } catch (error) {

  //     }
  //   }
  // };

  return (
    <SlideFade unmountOnExit={true} in={fade} offsetY="50px">
      <Container>
        {/* <ImageContainer>
        <img src="/dubpay-short-logo.png" alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
      </ImageContainer> */}
        <Row>
          <Title>
            <CustomText type={"h1"}>Seja bem-vindo!</CustomText>

            <CustomText type={"h4"}>
              Selecione uma das opções para continuar
            </CustomText>
          </Title>
        </Row>
        <SelectTypeUserContainer>
          <SelectTypeModal>
            <SelectTypeUserBody onClick={() => handleNextPage("cpf")}>
              <Row>
                <BodyIcon>
                  <Icon as={FcBusinessman} boxSize="60px" />
                </BodyIcon>
              </Row>
              <Row>
                <CustomText type={"h7"}>
                  Eu quero receber na pessoa física (CPF)
                </CustomText>
              </Row>
              <Row>
                <CustomButton variant={"primary"} isFullWidth={true}>
                  Selecionar
                </CustomButton>
              </Row>
            </SelectTypeUserBody>
          </SelectTypeModal>

          <SelectTypeModal>
            <SelectTypeUserBody onClick={() => handleNextPage("cnpj")}>
              <Row>
                <BodyIcon>
                  <Icon as={FcFactory} boxSize="60px" />
                </BodyIcon>
              </Row>
              <Row>
                <CustomText type={"h7"}>
                  Quero receber na pessoa jurídica (CNPJ)
                </CustomText>
              </Row>
              <Row>
                <CustomButton variant={"primary"} isFullWidth={true}>
                  Selecionar
                </CustomButton>
              </Row>
            </SelectTypeUserBody>
          </SelectTypeModal>
        </SelectTypeUserContainer>
      </Container>
    </SlideFade>
  );
};

export default FinishStep;
