import React, { useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Fade,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import api from "services/api";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useEmailStore } from "store/reducers/email";
import axios from "axios";
import $ from "jquery";
import "jquery-mask-plugin";
import "bootstrap";
import { CustomInput } from "styleguide/Input"
import { CustomButton } from "styleguide/Button"
import { toastSuccess, toastError } from "styleguide/Toastify"
import { updatePersonalData } from "services/userRequests"
import { useUserStore } from 'store/reducers/user';

const SignUp = () => {
  const [auth, updateUser] = useUserStore((state) => [state.auth, state.updateUser]);
  const emailStore = useEmailStore((state) => state.email);

  const { push } = useHistory();


  //const auth = useUserStore((state) => state.auth);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [loading, setLoading] = React.useState(false);
  const [fade, setFade] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [complement, setComplement] = React.useState("");
  const [birthdate, setBirthDate] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [neighbor, setNeighbor] = React.useState("");
  const [invoicing, setInvoicing] = React.useState("");
  const [erro, setErro] = React.useState("");

  useEffect(() => {
    setFade(true)
  }, [])

  const handleSignUp = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const currentDate = new Date();
    const birthDate = new Date(birthdate);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const month = currentDate.getMonth() - birthDate.getMonth();

    if (age < 18 || (age === 18 && month < 0)) {
      handleShowerro();
      setErro("Você precisa ser maior de 18 anos para se cadastrar.");
      setLoading(false);
      return;
    }

    if (zipcode.length < 9) {
      handleShowerro()
      setErro('Seu cep não foi encontrado ou está incorreto, por favor digite novamente!');
      setZipcode('');
      setLoading(false);
    } else if (invoicing === "") {
      handleShowerro()
      setErro('Selecione um faturamento');
    } else {

      try {

        const response = await updatePersonalData({
          address,
          name: auth.user.name,
          birth_date: birthdate,
          city,
          complement,
          cpf: auth.user.cpf,
          number,
          state,
          zipcode,
          invoicing,
          neighborhood: neighbor
        });

        if (response.success) {
          handleShow();
        }

      } catch (error: any) {
        handleShowerro()


        setErro(error.response.data[0]);
      } finally {
        setLoading(false);
      }
    }
  };


  $(() => {
    $('#birthdate').mask('00/00/0000');
    $('#cep').mask('00000-000');
    $('#imagemLogo').attr('style', 'position:fixed;');
    $('#texto').html('Olá ' + auth.user.name + ', você está quase lá.')
  });




  const [modalshow, setModal] = React.useState(false);
  const handleClose = () => {
    setModal(false);
    push("/auth/sign-in");
  };
  const handleShow = () => setModal(true);

  const [modalerro, setModalerro] = React.useState(false);
  const handleCloseerro = () => {
    setModalerro(false);
  };
  const handleShowerro = () => setModalerro(true);

  const invoicingData = [
    {
      id: 1,
      label: 'Começando agora'
    },
    {
      id: 2,
      label: 'Até R$ 10.000'
    },
    {
      id: 3,
      label: 'De R$ 10.000 à R$ 100.000'
    },
    {
      id: 4,
      label: 'De R$ 100.000 à R$ 500.000'
    },
    {
      id: 5,
      label: 'De R$ 500.000 à R$ 1.000.000'
    },
    {
      id: 6,
      label: 'Acima de R$ 1.000.000'
    }
  ];
  const getZipcodeData = () => {
    let novozipcode = zipcode.replace('-', '');
    if (novozipcode.length == 8) {
      axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
        .then((response) => {
          if (response.data.erro) {
            handleShowerro()
            setErro('Seu cep não foi encontrado ou está incorreto, por favor digite novamente!');
            setZipcode('');
          } else {
            setAddress(response.data.logradouro);
            setCity(response.data.localidade);
            setState(response.data.uf);
          }
        })
        .catch();
    }
  }

  return (

    <Fade in={fade}>


      <DefaultAuth illustrationBackground={illustration} image={illustration}>

        <Flex
          className="mt-0 pt-0"
          maxW={{ base: "100%", md: "max-content", lg: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="auto"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "0px", md: "10px" }}
          px={{ base: "0px", md: "0px" }}
          flexDirection="column"
        >



          <Modal isOpen={modalshow} onClose={handleClose}>
            <ModalContent>
              <ModalHeader>Agora só aguardar!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>Muito obrigado por fornecer suas informações, vamos agora para o acesso!</ModalBody>
              <ModalFooter>
                <Button variant="primary" onClick={handleClose}>
                  Fazer o login
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>


          <Modal isOpen={modalerro} onClose={handleCloseerro}>
            <ModalContent>
              <ModalHeader>Ops, tivemos um erro!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{erro}</ModalBody>
              <ModalFooter>
                <Button variant="primary" onClick={handleCloseerro}>
                  Tentar Novamente
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Box me="auto" className="mt-0 pt-0">
            <Heading color={textColor} fontSize="36px" mb="5px" className="cadastro">
              Vamos Continuar o Cadastro?
            </Heading>
            <Text
              id="texto"
              className="cadastro"
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Você está quase lá.
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            className="pt-0"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
            h="100%"
            display={'flex'}
          >
            <form
              onSubmit={(e) => {
                handleSignUp(e);
              }}
            >
              <FormControl>
                <Stack spacing={2}>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    CEP<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      id="cep"
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={zipcode}
                      onKeyUp={getZipcodeData}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Endereço residencial<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Cidade<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={city}
                      isDisabled={true}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Bairro<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={neighbor}
                      isDisabled={true}
                      onChange={(e) => setNeighbor(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Estado<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={state}
                      isDisabled={true}
                      onChange={(e) => setState(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Complemento
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={complement}
                      onChange={(e) => setComplement(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Nº<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Faturamento<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Select placeholder='Selecione...' onChange={(e) => setInvoicing(e.target.value)}>
                      {
                        invoicingData.map(invoicing => (
                          <option key={invoicing.id} value={invoicing.label}>{invoicing.label}</option>
                        ))
                      }
                    </Select>
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <FormLabel
                    ms="4px"
                    mt="12px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Data Nasc:<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <CustomInput
                      id="birthdate"
                      isRequired={true}
                      fontSize="sm"
                      mb="24px"
                      size="lg"
                      type="text"
                      variant="auth"
                      value={birthdate}
                      onChange={(e) => setBirthDate(e.target.value)}
                    />
                    <InputRightElement display="flex" alignItems="center" mt="4px">
                    </InputRightElement>
                  </InputGroup>

                  <CustomButton
                    type="submit"
                    isFullWidth={true}
                    fontSize="sm"
                    variant="primary"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                  >
                    {loading ? "Carregando..." : "Cadastrar"}
                  </CustomButton>
                </Stack>
              </FormControl>
            </form>
          </Flex>
        </Flex>
      </DefaultAuth>
    </Fade>
  );
};

export default SignUp;
